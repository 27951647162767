<template>
  <div
    class="MyPageNavigation relative deskStd:pt-0
    type-xxs-medium deskStd:type-sm-medium leading-single
    flex flex-col
    bg-lightest deskStd:bg-unset"
    :class="{
      isDropdown,
      'MyPageNavigationHeight deskStd:sticky deskStd:top-80': !isDropdown
    }"
  >
    <div class="deskStd:order-2 p-16 deskStd:p-0 hidden deskLargest:block">
      <div class="border-b deskStd:border-none border-light w-full flex items-center pb-20">
        <div v-if="!userStore.isSalesRepUser || userStore.userProfile.impersonatingCompany" class="flex">
          <div class="basis-40 w-40 h-40 shrink-0 rounded-full bg-brightGreen p-6 border-2 border-lighter">
            <img src="/icons/smiley.svg">
          </div>
          <div v-if="userStore.userProfile.impersonatingCompany" class="basis-40 w-40 h-40 shrink-0 rounded-full bg-brightGreen p-6 border-2 border-lighter -ml-8">
            <img src="/icons/smiley.svg">
          </div>
        </div>
        <div v-if="!userStore.isSalesRepUser || userStore.userProfile.impersonatingCompany" class="basis-full ml-12 relative top-2">
          <div class="type-xs-medium deskStd:type-sm-medium">{{ userStore.userProfile.company.name }}</div>
          <div class="text-dark type-xxs deskStd:type-xs mt-2">{{ userStore.userProfile.company.orgNo }}</div>
        </div>

        <button
          class="option basis-24 shrink-0 deskStd:!hidden tabletPortraitOnly:!hidden"
          :class="{
            selected: selected === 'settings'
          }"
          @click="$emit('show', 'settings')"
        >
          <img
            :src="selected === 'settings' ? '/mypage/settings-solid.svg' : '/mypage/settings.svg'"
            class="w-24 h-24"
            alt=""
          >
        </button>
      </div>
    </div>
    <div
      class="deskStd:order-1 border-b deskStd:border-none border-light"
      :class="{
        'bg-lighter': !isDropdown,
      }"
    >
      <div v-if="!isDropdown" class="hidden deskStd:block type-headline-xl mb-12 deskStd:mb-24">
        {{ $t(navigationHeading) }}
      </div>

      <div
        class="gap-12 px-12 overflow-x-auto py-16 "
        :class="{
          'flex tabletPortrait:grid tabletPortrait:grid-cols-9 deskStd:block': !isDropdown,
          'block': isDropdown,
        }"
      >
        <button
          v-for="item in filteredList"
          :key="item.type"
          class="option"
          :class="{
            selected: selected === item.type
          }"
          @click="$emit('show', item.type)"
        >
          <img
            :src="selected === item.type ? item.activeIcon : item.icon"
            class="w-24 h-24"
            alt=""
          >
          <div :class="{ 'hidden deskStd:block': item.langKeyMobile }">{{ $t(item.langKey) }}</div>
          <div v-if="item.langKeyMobile" class="deskStd:hidden">{{ $t(item.langKeyMobile) }}</div>
          <div
            v-if="userStore.getNotificationByType(item.notificationKey).length"
            class="hidden bg-brightGreen text-darkest deskStd:flex justify-center type-xxs-medium w-24 h-20 ml-16 pt-3"
          >
            {{ userStore.getNotificationByType(item.notificationKey).length }}
          </div>
        </button>
        <button
          class="option"
          @click="userStore.logOut"
        >
          <img
            src="/mypage/exit.svg"
            class="w-24 h-24"
            alt=""
          >
          <div>
            {{ $t('mypage.logout') }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user';

export type NavigationItem = {
  type: string;
  activeIcon: string;
  icon: string;
  langKey: string;
  langKeyMobile?: string
  notificationKey?: string;
  hidden?: boolean;
  authorized?: boolean;
};

const userStore = useUserStore();

const props = defineProps<{
  selected: string,
  navigationHeading: string;
  itemList: NavigationItem[];
  isDropdown: boolean;
}>();

defineEmits(['show']);

const filteredList = computed(() => {
  return props.itemList.filter(item => !item.hidden).filter(item => item.authorized);
});

</script>

<style scoped lang="postcss">
.option {
  @apply flex flex-col deskStd:flex-row items-center deskStd:text-dark deskStd:mb-24;
  img {
    @apply w-24 h-24;
  }
  >div {
    @apply deskStd:ml-12 mt-8 deskStd:mt-0 text-dark whitespace-nowrap;
  }
  &.selected {
    >div {
      @apply text-darkest;
    }
  }
  &:last-of-type {
    @apply mb-0;
  }
}
.isDropdown {
  .option {
    @apply flex-row text-dark mb-24;
    img {
      @apply w-24 h-24;
    }
    >div {
      @apply ml-12 mt-0;
    }
  }
}
.MyPageNavigationHeight {
  @screen deskStd {
    min-height: calc(var(--vh, 1vh) * 100 - 200px);
  }
}
</style>
